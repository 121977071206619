<template>
  <b-col lg="12">
    <div class="section-title">
      <h4 class="p-3 mb-2">
        <span>Commissions Payable</span>
      </h4>
      <a v-b-toggle.commissions-payable class="d-block">
        <i
            class="
                                        i-Arrow-Down-2
                                        cursor-pointer
                                        text-25
                                        t-font-boldest
                                        text-secondary
                                    "
        ></i>
      </a>
    </div>
    <b-collapse id="commissions-payable" @shown="fetch">
      <div class="mb-24 d-flex justify-content-between">
        <p>Total payable: {{ currencyInvoice(total) }}</p>
        <div v-if="user && user.is_introducer_admin" class="flex-row d-flex align-items-center mb-20">
          <label class="switch switch-secondary mt-2 ml-4">
            All Users
            <input
                v-model="filter.all"
                type="checkbox"
                @input="filterAll"
            />
            <span class="slider"></span>
          </label>
        </div>
      </div>
      <b-overlay
          :show="show.loading"
          rounded="sm"
          spinner-type="border"
          spinner-variant="primary"
      >

        <b-row>
          <b-col v-for="(commission, index) in results" :key="`commission${index}`" cols="6" class="pb-24">
            <CommissionPayableCard :item="commission"/>
          </b-col>
        </b-row>
      </b-overlay>

      <CardPagination
          :current-page="currentPage"
          :total-pages="totalPages"
          :results="results"
          :page-size="pageSize"
          :total-rows="totalRows"
          size="md"
          no-results-message="You have no commissions payable."
          @currentPageChange="currentPage=$event"
          @pageSizeChange="pageSize=$event"
          @fetch="fetch"
      />
    </b-collapse>

  </b-col>
</template>

<script>
import {http} from "@/services";
import {currencyFormat} from "@/mixins/currencyFormat";

import CommissionPayableCard from "@/views/introducer/reports/commissions/cards/CommissionPayableCard";
import CardPagination from "@/components/common/other/CardPagination";

export default {
  name: 'CommissionsPayable',
  mixins: [currencyFormat],
  components: {CardPagination, CommissionPayableCard},
  mounted() {
    this.fetch()
    this.fetchTotals()
  },
  data() {
    return {
      results: [],
      currentPage: 1,
      totalPages: 0,
      totalRows: 0,
      pageSize: 4,
      show: {
        loading: true
      },
      filter: {
        all: false
      },
      totals: null
    }
  },
  computed: {
    introducer() {
      return this.$store.getters.user?.is_introducer
    },
    paymentPlan() {
      return this.introducer?.payment_plan
    },
    handoverDetails() {
      // custom handover details with commission percentages
      return this.introducer?.handover_details
    },
    total() {
      return this.totals?.amount__sum
    },
    user() {
      return this.$store.getters.user
    }
  },
  methods: {
    fetch() {
      // fetches results
      let params = {
        page: this.currentPage,
        all: this.filter.all,
        page_size: this.pageSize
      }
      this.show.loading = true

      http.get('report_commissions_payable', {params: params}).then(
          response => {
            this.results = response.data.results
            this.currentPage = response.data.current_page_number
            this.totalPages = response.data.total_pages
            this.totalRows = response.data.total_objects
            this.pageSize = response.data.page_size
            this.show.loading = false
          }
      ).catch(
          error => {
            console.log(error)
            this.show.loading = false
          }
      )
    },
    fetchTotals() {
      let params = {
        all: this.filter.all
      }
      http.get('report_commissions_payable_totals', {params: params}).then(
          response => {
            this.totals = response.data
          }
      )
    },
    changePage(event) {
      // changes page
      this.currentPage = event
      this.fetch()
    },
    filterAll() {
      this.filter.all = !this.filter.all
      this.fetch()
      this.fetchTotals()
    }
  }
}
</script>
