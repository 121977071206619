<template>
  <b-container>
    <CommissionsOverview/>
    <CommissionsPending/>
    <CommissionsPayable/>
    <CommissionsPaid/>
    <RemittancePaid v-if="user && user.is_introducer_admin"/>
    <Attorneys/>
  </b-container>
</template>

<script>
import CommissionsPending from "@/views/introducer/reports/commissions/CommissionsPending";
import CommissionsPaid from "@/views/introducer/reports/commissions/CommissionsPaid";
import CommissionsPayable from "@/views/introducer/reports/commissions/CommissionsPayable";
import RemittancePaid from "@/views/introducer/reports/commissions/RemittancePaid";
import CommissionsOverview from "@/views/introducer/reports/commissions/CommissionsOverview";
import Attorneys from "@/views/introducer/reports/commissions/Attorneys";

export default {
  name: 'ReportCommissions',
  components: {CommissionsOverview, RemittancePaid, CommissionsPayable, CommissionsPaid, CommissionsPending, Attorneys},
  computed: {
    user() {
      return this.$store.getters.user
    }
  }
}
</script>
