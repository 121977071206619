<template>
  <b-col lg="12">
    <div class="section-title">
      <h4 class="p-3 mb-2">
        <span>Attorneys
          <small v-if="introducer_admin && !formData.introducer">({{ 'All of your users' }} - {{ formData.period }})</small>
          <small v-else-if="introducer_admin && formData.introducer_name">({{ formData.introducer_name }} -
            {{ formData.period }})</small>
        </span>
      </h4>

      <a v-b-toggle.attorneys class="d-block">
        <i class="
                                        i-Arrow-Down-2
                                        cursor-pointer
                                        text-25
                                        t-font-boldest
                                        text-secondary
                                    "></i>
      </a>

      <b-link @click="config">
        <i class="text-25 text-secondary i-Gear"></i>
      </b-link>

    </div>
    <b-collapse id="attorneys" @shown="fetch">

      <b-overlay :show="show.loading" rounded="sm" spinner-type="border" spinner-variant="primary">

        <b-table :borderless="true" :fields="fields" :items="tableData" class="p-20" striped show-empty>
          <template #cell(client)="data">
            {{ clientNameFull(data.item.client) }}
          </template>
          <template #cell(created_date)="data">
            {{ formatDateAndTime(data.item.created_date) }}
          </template>

        </b-table>


      </b-overlay>

    </b-collapse>

    <ConfigModal v-model="show.configModal" :isIntroducerAdmin="show.isIntroducerAdmin" :formData="formData"
      @change="set" />

  </b-col>
</template>

<script>
import { http } from "@/services";
import { clientHelpers } from '@/mixins/clientHelpers';
import { dateFormat } from '@/mixins/dateFormat';
import ConfigModal from "@/views/introducer/statistics/ConfigModal";


export default {
  name: 'Attorneys',
  mixins: [
    clientHelpers,
    dateFormat,
  ],
  components: {
    ConfigModal,
  },
  watch: {
    user: {
      deep: true,
      immediate: true,
      handler(value) {
        if (value) {
          this.formData.introducer = null
          this.formData.introducer_name = null
          this.show.isIntroducerAdmin = !!this.user?.is_introducer_admin
        }
      }
    },
  },
  mounted() {
    this.fetch()
  },
  data() {
    return {
      formData: {
        period: 'Last 6 Months',
        introducer: null,
        introducer_name: null,
      },
      results: [],
      results_temp: [],
      fields: [
        {
          key: 'client',
          label: 'Client'
        },
        {
          key: 'status',
          label: 'LPA Status'
        },
        {
          key: 'created_date',
          label: 'Created At'
        },
        {
          key: 'name',
          label: 'Attorney'
        },
        {
          key: 'phone',
          label: 'Phone'
        },
        {
          key: 'email',
          label: 'Email'
        },
        {
          key: 'type',
          label: 'Type'
        },
        {
          key: 'priority',
          label: 'Priority'
        }
      ],
      show: {
        configModal: false,
        isIntroducerAdmin: !!this.user?.is_introducer_admin,
        loading: true
      },
      filter: {
        all: false
      },
      totals: null
    }
  },
  computed: {
    user() {
      return this.$store.getters.user
    },
    introducer() {
      return this.user?.is_introducer
    },
    introducer_admin() {
      return !!this.user?.is_introducer_admin
    },
    tableData() {
      let attorneys = [];
      if (this.results.length) {
        this.results.forEach(item => {

          // Health - Primary
          item.data__attorneys__health__details__primary.forEach(a => {
            const name = `${a.name_first} ${a.name_middle || ''} ${a.name_last}`
            if (!attorneys.find(x => x.name == name && x.client.id == item.client.id)) {
              attorneys.push({
                client: item.client,
                created_date: item.created_date,
                status: item.status,
                name: name,
                phone: a.phone,
                email: a.email,
                type: 'Health',
                priority: 'Primary',
              })
            }
          });

          // Health - Backup
          item.data__attorneys__health__details__backup.forEach(a => {
            const name = `${a.name_first} ${a.name_middle || ''} ${a.name_last}`
            if (!attorneys.find(x => x.name == name && x.client.id == item.client.id)) {
              attorneys.push({
                client: item.client,
                created_date: item.created_date,
                status: item.status,
                name: name,
                phone: a.phone,
                email: a.email,
                type: 'Health',
                priority: 'Backup',
              })
            }
          });

          // Property - Primary
          item.data__attorneys__property__details__primary.forEach(a => {
            const name = `${a.name_first} ${a.name_middle || ''} ${a.name_last}`
            if (!attorneys.find(x => x.name == name && x.client.id == item.client.id)) {
              attorneys.push({
                client: item.client,
                created_date: item.created_date,
                status: item.status,
                name: name,
                phone: a.phone,
                email: a.email,
                type: 'Property',
                priority: 'Primary',
              })
            }
          });

          // Property - Backup
          item.data__attorneys__property__details__backup.forEach(a => {
            const name = `${a.name_first} ${a.name_middle || ''} ${a.name_last}`
            if (!attorneys.find(x => x.name == name && x.client.id == item.client.id)) {
              attorneys.push({
                client: item.client,
                created_date: item.created_date,
                status: item.status,
                name: name,
                phone: a.phone,
                email: a.email,
                type: 'Property',
                priority: 'Backup',
              })
            }
          });

        });
      }
      return attorneys;
    },
  },
  methods: {
    config() {
      this.show.configModal = true
    },
    set(val) {
      this.formData = val;
      this.fetch();
    },
    fetch() {
      // fetches results
      this.show.loading = true

      http.get('report_attorneys', { params: this.formData }).then(
        response => {
          this.results = response.data
          this.show.loading = false
        }
      ).catch(
        error => {
          console.log(error)
          this.show.loading = false
        }
      )

    },
  }
}
</script>
