<template>
  <b-card>
    <div
        class="card-transaction-product"
    >
      <div
          class="card-transaction-product-header align-item-center flex-nowrap"
      >
        <div>
          <h3 class="ul-widget__head-title mb-1">
            {{ title }}
          </h3>

          <template v-if="clients">
            <small
                v-for="(client, index) in clients"
                :key="index"
                class="client-names"
            >
              {{ clientNameShort(client) }}
            </small>
          </template>

          <ul>
            <li v-if="item.introducer_user">Status: {{ status }}</li>
            <li v-if="item.introducer_user">Payable to: {{ item.introducer_user.name }}</li>
            <li v-if="productTotal">Product total: {{ currencyInvoice(productTotal) }}</li>
            <li>Commission payable: <b>{{ currencyInvoice(item.amount) }}</b> ({{ item.rate}}%)</li>
          </ul>

          <small class="text-muted ml-8"
          >Date created:
            <span class="font-weight-500">{{
                formatDate(item.created_date)
              }}</span>
          </small>
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
import {clientHelpers} from '@/mixins/clientHelpers';
import {currencyFormat} from "@/mixins/currencyFormat";
import {textHelpers} from "@/mixins/textHelpers";
import { dateFormat } from "@/mixins/dateFormat";

export default {
  name: 'CommissionPendingCard',
  mixins: [clientHelpers, currencyFormat, textHelpers, dateFormat],
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    title () {
      if (this.item.mortgage) return 'Mortgage Commission'
      if (this.item.invoice) return 'Estate Planning Commission'
      if (this.item.policy) return 'Policy Commission'
      return 'Other Commission'
    },
    clients () {
      if (this.item.invoice?.transaction?.clients) return this.item.invoice.transaction.clients
      if (this.item.mortgage?.transaction?.clients) return this.item.mortgage.transaction.clients
      if (this.item.policy?.transaction?.clients) return this.item.policy.transaction.clients
      return null
    },
    productTotal () {
      if (this.item.invoice) return this.item.invoice.total
      if (this.item.mortgage) return this.item.mortgage.proc_received_amount
      if (this.item.policy) return this.item.policy.commission_received_amount
      return 0
    },
    status () {
      if (this.item.policy?.status) return this.item.policy.status
      if (this.item.mortgage?.status) return this.item.mortgage.status
      if (this.item.invoice?.status) return this.item.invoice.status
      return 'NO STATUS'
    }
  }
}
</script>
