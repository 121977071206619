<template>
  <b-overlay
      :show="show.loading"
      rounded="sm"
      spinner-type="border"
      spinner-variant="primary"
  >
    <b-row>
      <ChartTrend
          :series="series"
          :sub-title="subTitle"
          :extra-info="extraInfo"
          title="Estate Planning"
          @edit="$bvModal.show('estate-planning-edit-modal')"
      />
    </b-row>

   <CommissionOverviewEditModal
       id="estate-planning-edit-modal"
       :formData="{...form}"
       @change="fetch"
   />

  </b-overlay>
</template>

<script>
import {http} from "@/services";
import moment from "moment";
import { options } from "@/views/introducer/reports/commissions/modals/options";

import ChartTrend from "@/components/common/other/chart/ChartTrend";
import CommissionOverviewEditModal from "@/views/introducer/reports/commissions/modals/CommissionOverviewEditModal";

export default {
  name: 'CommissionsEstatePlanningChart',
  mixins: [options],
  components: {CommissionOverviewEditModal, ChartTrend},
  mounted() {
    this.fetch()
  },
  data() {
    return {
      show: {
        loading: true
      },
      rawData: [],
      series: [],
      form: {
        dateRange: [
          moment().subtract(6, 'month').startOf("month").format("YYYY-MM-DD"),
          moment().endOf("month").format("YYYY-MM-DD")
        ],
        frequency: 'W-MON',
        all: !!this.user?.is_introducer_admin
      }
    }
  },
  methods: {
    fetch(data=null) {

      if (data) {
        this.form = {...data}
      }
      this.show.loading = true
      // fetches results
      let params = {
        from_date: this.form.dateRange[0],
        to_date: this.form.dateRange[1],
        frequency: this.form.frequency,
        all_users: this.form.all
      }
      http.get('report_commissions_overview_estate_planning', {params: params}).then(
          response => {
            this.show.loading = false
            this.rawData = response.data
            this.convertData()
          }
      ).catch(
          error => {
            console.log(error)
            this.show.loading = false
          }
      )
    },
    convertData() {
      this.series = []
      this.series.push({
        name: 'Amount',
        smooth: false,
        data: this.rawData.map(month => ({
          x: `${new Date(month.date).toLocaleString('default', {month: 'long'})} ${new Date(month.date).toLocaleString('default', {year: 'numeric'})}`,
          y: month.total
        }))
      })

    }
  },
  computed: {
    user () {
      return this.$store.getters.user
    },
    subTitle () {
      return `${this.form.dateRange[0]} - ${this.form.dateRange[1]}`
    },
    extraInfo () {
      return `${this.frequency} ${this.form.all ? 'All Users' : ''}`
    },
    frequency () {
      return this.frequencyOptions.find(option => option.value === this.form.frequency).label
    }
  }
}
</script>
