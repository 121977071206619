<template>
  <div>
    <b-alert v-if="!results.length"
             class="mt-20 mb-30"
             show
             variant="alert alert-card alert-warning text-center">
      <p class="pt-16 pb-6">
        {{ noResultsMessage }}
      </p>
    </b-alert>

    <div class="d-flex  justify-content-between">

      <div v-if="totalRows" class="align-self-center">
        <b-input-group
            :size="size"
            prepend="Page Size"
        >
          <b-select
              v-model="pageSizeData"
              @input="changePageSize"
              :options="pageSizeOptions"
              :size="size" />
        </b-input-group>
      </div>

      <b-pagination
          v-if="results.length && totalPages > 1"
          v-model="currentPageData"
          pills
          align="center"
          :per-page="pageSizeData"
          :total-rows="totalRows"
          :size="size"
          @change="changePage"
          class="align-self-center"
      >
      </b-pagination>

      <div v-if="totalRows" class="align-self-center">
        <span class="mr-4" variant="primary">{{ totalRows }} {{ foundMessage }}</span>
      </div>

    </div>
  </div>

</template>
		
<script>
export default 	{
  name: "CardPagination",
  props: {
    results: {
      type: Array,
      required: true
    },
    totalRows: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    },
    pageSize: {
      type: Number,
      required: true
    },
    totalPages: {
      type: Number,
      required: true
    },
    noResultsMessage: {
      type: String,
      default: "No results found"
    },
    foundMessage: {
      type: String,
      default: "Results Found"
    },
    size: {
      type: String,
      default: 'lg'
    }
  },
  watch: {
    pageSize: {
      immediate: true,
      handler(value) {
        // add custom page size number to options array
        if (!this.pageSizeOptions.includes(value)) {
          this.pageSizeOptions.push(value);
        }
      }
    }
  },
  data() {
    return {
      pageSizeOptions: [1, 2, 3, 5, 10, 15, 20, 50, 100]
    };
  },
  methods: {
    changePage() {
      this.$nextTick(() => {
        this.$emit("fetch");
      });
    },
    changePageSize () {
      this.currentPageData = 1
      this.$emit('fetch')
    }
  },
  computed: {
    currentPageData: {
      set(value) {
        this.$emit("currentPageChange", value);
      },
      get() {
        return this.currentPage;
      }
    },
    pageSizeData: {
      set(value) {
        this.$emit("pageSizeChange", Number(value));
      },
      get() {
        return Number(this.pageSize);
      }
    }
  }
};
</script>
