<template>
  <b-modal :id="id"
           centered
           modal-class="modal-style-one"
           no-close-on-backdrop
           size="lg"
           @ok="set"
           title="Chart Options">
    <b-row>
      <b-col md="4">
        <label>Frequency</label>
        <v-select v-model="form.frequency"
                  :options="frequencyOptions"
                  :reduce="(item) => item.value"
                  placeholder="Select Frequency"/>
      </b-col>

      <b-col md="4">
        <label>Users</label>
        <v-select v-model="form.all"
                  :options="allOptions"
                  :reduce="(item) => item.value"
                  placeholder="Select All Users"/>
      </b-col>

    </b-row>
    <b-row class="mt-2">
      <b-col>
        <label>Date Range</label>
        <date-picker v-model="form.dateRange"
                     :shortcuts="rangeShortcuts"
                     class="w-75 cal-icon"
                     format="YYYY-MM-DD"
                     input-class="form-control form-control-lg"
                     placeholder="Select date"
                     range
                     type="date"
                     value-type="format">
          <template v-slot:icon-calendar
                    class="cal-icon">
            <i class="text-20 i-Dec"></i>
          </template>
          <template v-slot:icon-clear
                    class="cal-icon">
            <i class="text-20 i-Close-Window"></i>
          </template>
        </date-picker>
      </b-col>

    </b-row>

  </b-modal>
</template>

<script>
import vSelect from "vue-select";
import { options } from "@/views/introducer/reports/commissions/modals/options";

export default {
  name: 'CommissionOverviewEditModal',
  mixins: [options],
  components: {
    vSelect
  },
  props: {
    id: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    }
  },
  watch: {
    formData: {
      deep: true,
      immediate: true,
      handler(value) {
        if (value) this.setForm(value)
      }
    }
  },
  data() {
    return {
      form: {
        frequency: null,
        dateRange: null,
        all: null
      },
      rangeShortcuts: [
        {
          text: 'This Week',
          onClick() {
            const d = new Date();
            var day = d.getDay();
            const date = [
              new Date(d.getFullYear(),
                  d.getMonth(),
                  d.getDate() + (day == 0 ? -6 : 1) - day),
              new Date(d.getFullYear(),
                  d.getMonth(),
                  d.getDate() + (day == 0 ? 0 : 7) - day)
            ];
            return date;
          }
        },
        {
          text: 'This Month',
          onClick() {
            const d = new Date();
            const date = [
              new Date(d.getFullYear(),
                  d.getMonth(),
                  1),
              new Date(d.getFullYear(),
                  d.getMonth() + 1,
                  0)
            ];
            return date;
          }
        },
      ],
    }
  },
  methods: {
    setForm(data) {
      this.form = {...data}
    },
    set () {
      this.$emit('change', this.form)
    }
  }
}
</script>
