<template>
  <b-card class="mb-30 o-hidden"
          no-body
          title="">
    <b-row>
      <b-col>
        <div class="d-flex justify-content-between p-16">
          <div class="d-flex justify-content-between align-items-end">
            <h3 class="mb-0">{{ title }}</h3>
            <div class="ml-2 mb-0">
              <small>{{ subTitle }}</small><br>
              <small class="font-italic">{{ extraInfo }}</small>
            </div>
          </div>
          <div>
            <div class="mb-0 inline">
              <b-link @click="$emit('edit')"><i class="i-Gear text-secondary text-25"></i></b-link>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-card-body class="ul-card__widget-chart">
      <div class="chart-wrapper"
           style="height: 150px; width: 100%">
        <apexchart
            :options="chartOptions"
            :series="series"
            height="150"
            type="bar"/>
      </div>
    </b-card-body>


  </b-card>
</template>

<script>
import {dateFormat} from "@/mixins/dateFormat";
import "@/plugins/apexChart.js";

export default {
  name: 'ChartTrend',
  mixins: [dateFormat],
  props: {
    series: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    extraInfo: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      chartOptions: {
        chart: {
          width: "100%",
          height: 100,
          sparkline: {
            enabled: true
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "smooth",
          width: 1
        },
        legend: {
          show: false
        },
        xaxis: {
          type: "category",
          labels: {
            show: true
          },
          axisTicks: {
            show: true
          },
          axisBorder: {
            show: true
          }
        },
        yaxis: {
          show: true,
          labels: {
            show: true
          }
        },
        grid: {
          show: true
        },
        tooltip: {
          enabled: true,
          x: {
            format: "MM"
          }
        },
        colors: [
          "#663069",
          "#4caf50"
        ]
      },

    }
  }
}
</script>
