<template>
  <b-col lg="12">
    <div class="section-title">
      <h4 class="p-3 mb-2">
        <span>Commissions Overview</span>
      </h4>
      <a v-b-toggle.commissions-overview class="d-block">
        <i class="i-Arrow-Down-2 cursor-pointer text-25 t-font-boldest text-secondary"
        ></i>
      </a>
    </div>
    <b-collapse id="commissions-overview" visible>

      <b-row>
        <b-col cols="auto" class="m-2">
          <CommissionsEstatePlanningChart
              v-if="allowedTransactions.includes('estate_planning')"
          />
        </b-col>

        <b-col cols="auto" class="m-2">
          <CommissionsPolicyChart
              v-if="allowedTransactions.includes('insurance')"
          />
        </b-col>

        <b-col cols="auto" class="m-2">
          <CommissionsMortgageChart
              v-if="allowedTransactions.includes('mortgage')"
          />
        </b-col>
      </b-row>

    </b-collapse>
  </b-col>
</template>

<script>
import {currencyFormat} from "@/mixins/currencyFormat";
import CommissionsEstatePlanningChart
  from "@/views/introducer/reports/commissions/cards/CommissionsEstatePlanningChart";
import CommissionsPolicyChart from "@/views/introducer/reports/commissions/cards/CommissionsPolicyChart";
import CommissionsMortgageChart from "@/views/introducer/reports/commissions/cards/CommissionsMortgageChart";

export default {
  name: 'CommissionsOverview',
  mixins: [currencyFormat],
  components: {
    CommissionsMortgageChart,
    CommissionsPolicyChart,
    CommissionsEstatePlanningChart
  },
  computed: {
    introducer() {
      return this.$store.getters.user?.is_introducer
    },
    user() {
      return this.$store.getters.user
    },
    allowedTransactions() {
      return this.introducer?.allowed_transaction_types ? this.introducer.allowed_transaction_types : []
    }
  }
}
</script>
