export const clientHelpers = {
    methods: {
        clientNameFull(client) {
            if (client)
                return `${client.profileNameFirst} ${
                    client.profileNameMiddle
                        ? `${client.profileNameMiddle} `
                        : ''
                }${client.profileNameLast}`;
            return 'NO NAME';
        },
        clientName(client) {
            if (client)
                return `${client.profileNameFirst} ${
                    client.profileNameMiddle
                        ? `${client.profileNameMiddle} `
                        : ''
                }${client.profileNameLast}`;
            return 'NO NAME';
        },
        clientNameFirstLast(client) {
            if (client)
                return `${client.profileNameFirst} ${client.profileNameLast}`;
            return 'NO NAME';
        },
        clientNameSurnameInitial(client) {
            if (client)
                return `${client.profileNameLast} ${client.profileNameFirst[0]}`;
            return 'NO NAME';
        },
        clientNameShort(client) {
            if (client)
                return `${client.profileNameFirst[0]} ${client.profileNameLast}`;
            return 'NO NAME';
        },
        clientFirstName(client) {
            if (client) return `${client.profileNameFirst}`;
            return 'NO FIRST';
        },
        clientInitials(client) {
            if (client && client.profileNameFirst && client.profileNameLast)
                return `${client.profileNameFirst[0]}${client.profileNameLast[0]}`;
            return 'NO NAME';
        },
        isSonOrDaughter(person) {
            if (person && person.relationship) {
                return !!(
                    person.relationship === 'son' ||
                    person.relationship === 'daughter'
                );
            } else if (person && person.relationship_to_client) {
                return !!(
                    person.relationship_to_client === 'son' ||
                    person.relationship_to_client === 'daughter'
                );
            }
            return false;
        },
        isPartnerChecker(person, allowedRelationships) {
            if (person && person.relationship_to_client) {
                return !!allowedRelationships.includes(person.relationship_to_client);
            } else if (person && person.relationship) {
                return !!allowedRelationships.includes(person.relationship);
            }
            return false;
        },
        isPartner (person) {
            let allowedRelationships = [
                'husband',
                'wife',
                'partner',
                'civil partner',
                'intended husband',
                'intended wife'
            ];
            return this.isPartnerChecker(person, allowedRelationships)
        },
        isMarried (person) {
            let allowedRelationships = [
                'husband',
                'wife',
                'civil partner'
            ];
            return this.isPartnerChecker(person, allowedRelationships)
        },
        isPartnered (person) {
            let allowedRelationships = [
                'partner',
                'intended husband',
                'intended wife'
            ];
            return this.isPartnerChecker(person, allowedRelationships)
        },
        isChildOrDependant(person) {
            return this.isSonOrDaughter(person) || person.dependant;
        },
        isDependant(person) {
            return person.dependant
        },
        isMale(person) {
            if (person && person.relationship_to_client) {
                return !!(
                    person.relationship_to_client.includes('husband') ||
                    person.relationship_to_client.includes('brother') ||
                    person.relationship_to_client.includes('nephew') ||
                    person.relationship_to_client.includes('father') ||
                    person.relationship_to_client.includes('son')
                );
            } else if (person && person.relationship) {
                return !!(
                    person.relationship.includes('husband') ||
                    person.relationship.includes('brother') ||
                    person.relationship.includes('nephew') ||
                    person.relationship.includes('father') ||
                    person.relationship.includes('son')
                );
            }
            return false;
        },
        isFemale(person) {
            if (person && person.relationship_to_client) {
                return !!(
                    person.relationship_to_client.includes('wife') ||
                    person.relationship_to_client.includes('mother') ||
                    person.relationship_to_client.includes('niece') ||
                    person.relationship_to_client.includes('sister') ||
                    person.relationship_to_client.includes('daughter')
                );
            } else if (person && person.relationship) {
                return !!(
                    person.relationship.includes('wife') ||
                    person.relationship.includes('mother') ||
                    person.relationship.includes('niece') ||
                    person.relationship.includes('sister') ||
                    person.relationship.includes('daughter')
                );
            }
            return false;
        },
        sortClientPeople(people) {
            let p = Object.assign([], people);
            return p.sort((a, b) => {
                let nameA = a.name_first.toLowerCase();
                let nameB = b.name_first.toLowerCase();
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return 0;
            });
        },
        clientNameList(args) {
            // init
            let clientsData = 'clients' in args ? args.clients : [];
            let clientDetails =
                'clientDetails' in args ? args.clientDetails : null;
            let initials = 'initials' in args ? args.initials : false;
            let andOr = 'andOr' in args ? args.andOr : ' and ';
            let separator = 'separator' in args ? args.separator : ', ';
            let firstName = 'firstName' in args ? args.firstName : false;
            let nameEnding = 'nameEnding' in args ? args.nameEnding : null

            let nameShort = 'nameShort' in args ? args.nameShort : false;
            let previousNames =
                'previousNames' in args ? args.previousNames : false;
            let aliasNames = 'aliasNames' in args ? args.aliasNames : false;

            let nameSurnameInitial = 'nameSurnameInitial' in args ? args.nameSurnameInitial : false;
            let string = '';
            // start
            for (var i = 0; i < clientsData.length; i++) {
                let client = null;
                if (typeof clientsData[i] === 'number') {
                    if (clientDetails) {
                        client = clientDetails.find(
                            (client) => client.id === clientsData[i]
                        );
                    } else return 'Unknown Client';
                } else if (typeof clientsData[i] === 'object') {
                    client = clientsData[i];
                }
                // spaces, and, or
                if (i !== 0 && i !== clientsData.length - 1)
                    string += separator;
                if (i !== 0 && i === clientsData.length - 1) string += andOr;
                if (initials) string += this.clientInitials(client);
                else if (firstName) {
                    string += this.clientFirstName(client);
                }
                else if (nameShort) string += this.clientNameShort(client);
                else if (nameSurnameInitial) string += this.clientNameSurnameInitial(client);
                else string += this.clientName(client);

                if (nameEnding) {
                    string += nameEnding
                }

                if (previousNames && client.profilePreviousName) {
                    string += ` previously known as ${client.profilePreviousName}`;
                }
                if (aliasNames && client.profileAlias) {
                    string += ` also known as ${client.profileAlias}`;
                }
            }
            return string;
        },
        clientAddress(args) {
            // init
            let address = 'address' in args ? args.address : null;
            let client = 'client' in args ? args.client : null;
            let separator = 'separator' in args ? args.separator : ', ';
            let error = 'error' in args ? args.error : 'NO ADDRESS';
            let addressShort =
                'addressShort' in args ? args.addressShort : false;
            let string = '';
            // guards
            if (!address && !client) return error;
            if (client) {
                if (
                    client.current_address &&
                    typeof client.current_address === 'object'
                )
                    address = client.current_address;
                else if (
                    client.current_address_details &&
                    typeof client.current_address_details === 'object'
                )
                    address = client.current_address_details;
                else return error;
            }

            // start
            if (address.line_1) string += address.line_1 + separator;
            if (address.line_2 && !addressShort)
                string += address.line_2 + separator;
            if (address.post_town && !addressShort)
                string += address.post_town + separator;
            if (address.postcode) string += address.postcode;
            return string;
        }
    },
    data () {
        return {
            hasPartnerRelationships: [
                'Separated',
                'Civil Partner',
                'Married',
                'Engaged',
                'Partnered'
            ],
            policiesOthersNot: ['Life Cover', 'Life & Critical Illness', 'Critical Illness', 'Income Protection', 'Whole of Life'],
            liabilitiesMortgage: ['Mortgage (BTL)', 'Mortgage'],
            liabilitiesLoans: ['Loan'],
            liabilitiesCreditCards: ['Credit Card'],
            liabilitiesOtherNot: ['Mortgage (BTL)', 'Mortgage', 'Loan', 'Credit Card'],
            assetsOtherNot: ['Residential Home', 'Savings', 'Pensions', 'Shares', 'Vehicles', 'Household Contents']
        }
    }
};
